.kicontainer {
  display: grid;
  padding-top: 10vh;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* grid-template-rows: repeat(2, 1fr) 1fr 1fr; */
  /* grid-template-rows: repeat(4, 1fr); */
  /* grid-auto-rows: min-content; */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-family: "Inter", sans-serif;
  background-image: url("/public/topbackground.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
}

.kicontainer > div {
  width: fit-content;
  height: fit-content;
}

/* Buttons */
.readmore-button {
  background-color: transparent;
  color: #584f8b;
  border: 0.2vw solid #584f8b;
  border-radius: 6px;
  padding: 1em 2em;
  font-size: 1vw;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  margin-top: 5vh;
}

.readmore-button:hover {
  background-color: #584f8b50;
  color: #423b69;
}

.dropdown-icon {
  margin-top: -0.4vw;
  margin-left: 1vw;
  width: 1.5em;
  height: 1.5em;
}

.videosection {
  grid-area: 1 / 1 / 2 / 2;
  background-color: rgba(236, 236, 236, 0.7);
  border-radius: 1vh;
  padding: 1vw;
  width: fit-content; /* Breite an Inhalt anpassen */
  height: fit-content; /* Höhe an Inhalt anpassen */
  margin-left: 10vw;
  margin-top: 10vh;
}

.videocard {
  margin-top: 1vh;
  width: fit-content;
  height: 70vh;
  border-radius: 1vh;
  border-bottom: 1vw;
  padding-bottom: 2vh;
}

.headercard {
  grid-area: 1 / 2 / 2 / 3;
  /* max-height: 55vh;
  min-height: 55vh; */
  background-color: rgba(236, 236, 236, 0.7);
  border-radius: 1vw;
  font-size: 1.2vw;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-align: left;
  padding: 3vw;
  margin-right: 1vw;
  margin-top: 10vh;
}
.videosection,
.headercard,
.solutions,
.header-newsection-container {
  height: fit-content;
  margin-bottom: 1vw;
}

.headercard > li {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1vw;
  font-weight: 700;
  padding-left: 2.5em;
  position: relative;
  margin-bottom: 2vh;
}

.headercard > li::before {
  content: "";
  background-image: url("../../../public/icons/checkmark.svg");
  background-repeat: no-repeat;
  background-size: 1em 1em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
  height: 1em;
}

.headercard .heading-ki {
  line-height: 1.2;
}

.newsection {
  grid-area: 2 / 2 / 3 / 3;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1vw;
  padding: 2vw;
  margin-right: 4vw;
  max-height: fit-content;
  min-height: fit-content;
  justify-self: start;
  margin-top: 3vh;
  /* margin-left: -3vw; */
  font-weight: 600;
  font-size: 1vw;
}

.highlight {
  background: linear-gradient(90deg, #8a2be2, #da70d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.solutions {
  background-color: rgba(255, 255, 255, 0.7);
  grid-area: 2 / 1 / 3 / 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 2vw; */
  /* place-items: center;
  justify-content: center;
  align-items: center;
  text-align: center; */
  padding: 2vh;
  /* margin-top: -50vh; */
  font-size: 0.8vw;
  padding-left: 8vw;
  height: fit-content;
  overflow: hidden;
}

.full-width {
  grid-area: 1 / 1 / 2 / 3;
  padding: 2vh;
  text-align: center;
  font-size: 1.5vw;
  font-weight: 700;
  text-align: left;
}

.solution-little-card {
  background-color: white;
  border-radius: 1vw;
  height: fit-content;
  width: 50vw;
  margin-left: -35vw;
  border-radius: 1vw;
  padding: 1vw;
  font-size: 1vw;
  display: flex;
  place-items: center;
  justify-content: center;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.3);
}

.solution-central {
  grid-column: 2 / 3;
  /* background-color: rgba(255, 255, 255, 0.7); */
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 2vh;
  text-align: center;
  font-weight: 600;
  margin-right: 25vw;
}

.solutioncentral div {
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.solutionsleft {
  background-color: white;
  border-radius: 1vw;
  min-width: fit-content;
  max-width: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: right;
  align-items: right;
  /* padding: 4vh; */
}
.solutionsright {
  border-radius: 1vw;
  min-width: fit-content;
  max-width: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2vh; */
}

.iconholder {
  background: linear-gradient(to bottom, #a33697, #7970c1);
  /* min-width: 6vw;
  max-width: 6vw;
  min-height: 25vh;
  max-height: 25vh; */
  width: 6vw;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 1vw;
  margin-top: 1vw;
  margin-left: 0vw;
  margin-right: 0vw;
}
.solutionCard {
  font-size: 1vw;
  background-color: white;
  width: 30vw;
  border-radius: 1vw;
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
  margin-bottom: 4vh;
  text-align: left;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 4vh;
  gap: 1vw;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.3);
}

.solutionCard {
  min-height: 19.5vh;
}

.process {
  grid-area: 3 / 1 / 4 / 3;
  height: fit-content;
  background-color: white;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  place-items: center;
  justify-content: center;
  font-size: 2vw;
  font-weight: 700;
  margin-top: -3vh;
}
.process .text {
  background: #7970c1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.more {
  grid-area: 4 / 1 / 5 / 3;
  /* grid-column: 1 /1; */
  background-image: url("../../../public/bottombackground.svg");
  background-repeat: no-repeat;
  width: 100% !important;
  background-size: 100%;
  /* margin-left: 10vw; */
}

.more > p {
  color: #7970c1;
  padding-left: 17vw;
  font-size: 2vw;
  font-weight: 700;
  margin-top: 8vh;
}

.more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  padding: 2vh;
}

.more-container > div {
  background-color: rgba(255, 255, 255, 0.7);
  flex: 1 1 20vw;
  max-width: 15vw;
  min-width: 15vw;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 1vw;
  font-size: 1vw;
  font-weight: 700;
  gap: 4vh;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.3);
}

.more-container > div:nth-child(1):hover {
  cursor: pointer;
  color: #7970c1;
  background-color: white;
}

.more-container > div:nth-child(2):hover {
  cursor: pointer;
  color: rgba(243, 155, 132, 255);
  background-color: white;
}

.more-container > div:nth-child(3):hover {
  cursor: pointer;
  color: rgba(128, 0, 128, 1);
  background-color: white;
}

.more-container > div:nth-child(4):hover {
  cursor: pointer;
  color: rgba(158, 168, 214, 255);
  background-color: white;
}

.more-container > div:nth-child(1):hover img {
  filter: brightness(0) saturate(100%) invert(41%) sepia(55%) saturate(463%)
    hue-rotate(233deg) brightness(100%) contrast(95%);
}
.more-container > div:nth-child(2):hover img {
  filter: brightness(0) saturate(100%) invert(68%) sepia(78%) saturate(1358%)
    hue-rotate(0deg) brightness(93%) contrast(90%);
}
.more-container > div:nth-child(3):hover img {
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
}
.more-container > div:nth-child(4):hover img {
  filter: brightness(0) invert(76%) sepia(57%) saturate(834%) hue-rotate(200deg)
    brightness(93%) contrast(95%);
}
