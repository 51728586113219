.impressum-container {
  display: flex;
  flex-direction: column;
  background-image: url("/public/backgroundimpressum.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.close-icon {
  position: absolute;
  top: 1vw;
  right: 1vw;
  font-size: 2vw;
  cursor: pointer;
  color: black;
}

.impressum-card {
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  background-color: rgba(236, 236, 236, 0.7);
  height: 60vh;
  width: 80vw;
  margin-top: 15vh;
  padding-left: 5vw;
  padding-top: 13vh;
}
