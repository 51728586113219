.big-card-container {
  margin-top: 10vh;
  margin-left: 10vw;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  animation: slideInFromRight 1s ease-in-out;
}

.big-card.swipe-out {
  animation: swipeOutToLeft 0.8s ease-in-out;
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes swipeOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.big-card {
  position: relative;
  border-radius: 1vw;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.7);
  width: 75vw;
  height: auto;
  font-size: 0.8vw;
  padding: 20px;
  margin-bottom: 1vw;
  line-height: 2vh;
}

.close-icon {
  position: absolute;
  top: 1vw; /* Abstand von oben */
  right: 1vw; /* Abstand von rechts */
  font-size: 2vw;
  cursor: pointer;
  color: black;
  animation: swipeOutToLeft 0.8s ease-in-out;
}

.table1 {
  grid-area: 1 / 1 / 2 / 2;
  border-radius: 1vw;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: left;
  line-height: 2vh;
  width: 40vw;
}
.table2 {
  grid-area: 1 / 2 / 2 / 3;
  border-radius: 1vw;
  background-color: rgba(255, 255, 255, 0.4);
  width: 15vw;
}
.table3 {
  grid-area: 1 / 3 / 2 / 4;
  border-radius: 1vw;
  background-color: rgba(255, 255, 255, 0.4);
  width: 15vw;
}

.table-wrapper {
  display: flex; /* Flexbox für nebeneinander liegende Tabellen */
  justify-content: space-between; /* Gleichmäßige Verteilung der Tabellen */
  position: relative;
}

table {
  border-collapse: collapse;
  /* line-height: 2.5vh */
}

.table-line {
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #000; /* Durchgezogene Linie */
  z-index: 1; /* Hinter den Tabellen */
}

.table-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.table-wrapper table {
  margin: 0 1vw; /* Horizontaler Abstand zwischen den Tabellen */
  border-radius: 1vw;
}

/* Stil für die erste Tabelle (50vw) */
.table-50 {
  background-color: rgba(255, 255, 255, 0.7);
  width: 50vw; /* Breite 50% für die erste Tabelle */
}

/* Stil für die zweiten und dritten Tabellen (25vw) */
.table-25 {
  background-color: rgba(255, 255, 255, 0.7);
  width: 25vw; /* Breite 25% für die zweiten und dritten Tabellen */
}

th,
td {
  padding: 10px;
  border: none;
  height: 1vh;
}

.row-header {
  color: violet;
  font-weight: bold;
}
