/* Header-Stile */
header {
  height: 11vh; /* Höhe des Headers */
  max-height: 12vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparenter Hintergrund */
  gap: 1vw;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 1vw;
  transition: background-color 0.3s ease; /* Sanfter Übergang */
  z-index: 1; /* Damit der Header immer oben bleibt */
}

header.scrolled {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.6); /* Hintergrund wird transparent beim Scrollen */
}

.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url("/public/topbackground.svg"); */
  background-size: cover;
  backdrop-filter: blur(3px); /* Unschärfe-Effekt für den Hintergrund */
  z-index: -1;
  transition: backdrop-filter 0.3s ease; /* Sanfter Übergang beim Scrollen */
}

.background-blur.blurred {
  backdrop-filter: blur(3px); /* Stärkere Unschärfe bei mehr als 50px Scrollen */
}

.logo img {
  height: 2vw; /* Logo-Größe */
  cursor: pointer;
}

.cta-btn {
  font-family: "Inter", sans-serif;
  background: linear-gradient(to right, #a33697, #6e7fcc);
  color: white;
  font-size: medium;
  font-weight: 700;
  border-radius: 1vh;
  height: 6vh;
  width: 15vw;
  /* max-width: 100%; */
  white-space: nowrap;
  padding: 0.5em 1em;
  font-size: 0.8vw;
  cursor: pointer;
}

.cta-btn:hover {
  background: linear-gradient(to right, #6e7fcc, #a33697); /* Hover-Effekt */
}
