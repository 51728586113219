.datenschutz-container {
  display: flex;
  flex-direction: column;
  background-image: url("/public/backgroundimpressum.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  overflow-y: hidden;
}

.close-icon {
  position: absolute;
  top: 1vw;
  right: 1vw;
  font-size: 2vw;
  cursor: pointer;
  color: black;
}

.datenschutz-card {
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  background-color: rgba(236, 236, 236, 0.7);
  min-height: 60vh;
  width: 80vw;
  margin-top: 15vh;
  padding-left: 5vw;
  padding-right: 1.5vw;
  padding-top: 13vh;
  overflow-y: auto;
  text-align: justify;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

.datenschutz-card::-webkit-scrollbar {
  width: 0.4rem;
}

.datenschutz-card::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.datenschutz-card::-webkit-scrollbar-track {
  background: transparent;
}
