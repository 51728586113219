.nav-container {
  display: flex;
  position: fixed;
  top: 20vh;
  left: 3vw;
  border-radius: 1vw;
  background-color: rgba(236, 236, 236, 0.5);
  width: 3vw;
  max-width: 3vw;
  height: 60vh;
  max-height: 70vh;
  padding: 3em 1em;
  justify-content: center;
  align-items: stretch;
}

button {
  border: 0px solid transparent;
  background-color: transparent;
}

.nav-background-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}

.nav-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: space-between;
}

.nav-content > button:nth-child(1):hover img {
  cursor: pointer;
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nav-content > button:nth-child(2):hover img {
  cursor: pointer;
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nav-content > button:nth-child(3):hover img {
  cursor: pointer;
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nav-content > button:nth-child(4):hover img {
  cursor: pointer;
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.nav-content > button:nth-child(5):hover img {
  cursor: pointer;
  filter: brightness(0) invert(26%) sepia(57%) saturate(834%) hue-rotate(255deg)
    brightness(93%) contrast(95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
