.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2vw;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 0.8vw;
  font-weight: 700;
}

.ci-logo-area {
  flex: 0 0 auto;
}

.footer {
  display: flex;
  align-items: center;
}

.footer span {
  margin-right: 1vw;
}

.footer-container img:hover {
  cursor: pointer;
}

.footer span {
  cursor: pointer;
}
